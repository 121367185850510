<template>
  <b-card>
    <b-row>
      <b-col md="4">
        <b-form-group
          label="Veillez choisir le distributeur"
        >
          <v-select
            v-model="distributeur"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="nom"
            :options="distributeurs"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <!-- basic -->
        <b-form-group
          label="Date "
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
            v-model="date"
            type="date"
            placeholder="Entrez la date"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Semaine de la visite"
        >
          <b-badge
            variant="light-success"
            style="font-size: large"
          >
            Semaine : {{ semaine }}
          </b-badge>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-table
          responsive
          :items="products"
          :fields="fields"
          class="mb-0"
        >
          <template v-slot:cell(Produit)="data">
            <span
              v-if="!data.item.new_status"
              class="text-nowrap"
            >
              <strong>{{ data.item.produit_r.nom_produit }}</strong>
            </span>
          </template>
          <template v-slot:cell(Quantité-Entrée)="data">
            <b-form-input
              v-if="!data.item.new_status"
              v-model="data.item.quantity_entree"
              placeholder="Entrez la quantité entrée"
            />
          </template>
          <template v-slot:cell(Derniere-Quantité)="data">
            <span
              v-if="!data.item.new_status"
              class="text-nowrap"
            >
              <strong>{{ data.item.quantity }}</strong>
            </span>
          </template>
          <template v-slot:cell(Visite-Quantité)="data">
            <b-form-input
              v-model="data.item.quantity_new"
              placeholder="Entrez la quantité actuelle"
            />
          </template>
          <template v-slot:cell(Date-Peremption)="data">
            <b-form-input
              v-model="data.item.date_peremption"
              type="date"
              placeholder="Entrez la date de peremption"
            />
          </template>
          <template v-slot:cell(type)="data">
            <span
              v-if="!data.item.new_status"
              class="text-nowrap"
            >
              <strong>{{ data.item.produit_r.type }}</strong>
            </span>
          </template>
          <template v-slot:cell(Actions)="data">
            <span
              v-if="!data.item.new_status"
              class="text-nowrap"
            >
              <strong>
                <b-button
                  variant="gradient-primary"
                  class="btn-icon rounded-circle"
                  @click="addProduct(data.item)"
                >
                  <feather-icon icon="PlusCircleIcon" />
                </b-button>
              </strong>
            </span>
          </template>
        </b-table>
        <!-- button -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="btn_export"
          @click="save()"
        >
          <!--          <feather-icon icon="PlusCircleIcon" />-->
          Valider
        </b-button>
      </b-col>
    </b-row>
  </b-card>

</template>

<script>
import {
  BBadge,
  BButton,
  BCard, BCol, BFormGroup, BFormInput, BRow, BTable, VBModal,
} from 'bootstrap-vue'
import { API_URL } from '@/helpers/global-scops'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import htmlToPaper from 'vue-html-to-paper'
import showToast from '@/helpers/methodes'
import { getWeek } from '@amcharts/amcharts4/.internal/core/utils/Utils'
import { quantity } from 'echarts/lib/util/number'

export default {
  name: 'MainVue',
  components: {
    BBadge,
    BButton,
    BFormInput,
    BFormGroup,
    BTable,
    BRow,
    BCol,
    BCard,
    vSelect,
  },
  directives: {
    Ripple,
    htmlToPaper,
    'b-modal': VBModal,
  },
  data() {
    return {
      date: '',
      distributeur: {},
      semaine: '',
      products: [],
      distributeurs: [],
      fields: [
        'Produit',
        'Derniere-Quantité',
        'Quantité-Entrée',
        'Visite-Quantité',
        'Date-Peremption',
        'type',
        'Actions',
      ],
    }
  },
  watch: {
    distributeur(newValue, oldValue) {
      console.log(newValue.id, oldValue)
      this.$http.get(`${API_URL}distributeurs-produit-stocks/?distributeur=${newValue.id}`)
        .then(response => {
          this.products = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    // eslint-disable-next-line no-unused-vars
    date(newValue, oldValue) {
      const dateObj = new Date(newValue)
      const year = dateObj.getFullYear()
      const month = dateObj.getMonth() + 1 // Les mois sont indexés à partir de 0, donc ajoutez 1 pour obtenir le mois correct
      const day = dateObj.getDate()
      const monthStr = month < 10 ? `0${month}` : month
      const dayStr = day < 10 ? `0${day}` : day
      const formattedDate = `${year}-${monthStr}-${dayStr}`
      this.semaine = getWeek(new Date(formattedDate))
    },
  },
  created() {
    this.semaine = getWeek(new Date())
    this.getDistributeurs()
  },
  methods: {
    getProducts() {
      this.$http.get(`${API_URL}distributeur-stock/${this.$route.params.id}`)
        .then(response => {
          this.rows = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getDistributeurs() {
      this.$http.get(`${API_URL}distributeurs/?commercial_id=${this.$route.params.id}`)
        .then(response => {
          this.distributeurs = response.data
          // eslint-disable-next-line prefer-destructuring
          this.distributeur = this.distributeurs[0]
        })
        .catch(error => {
          console.log(error)
        })
    },
    save() {
      // Créez un nouvel objet Date à partir de la valeur de l'entrée
      const dateObj = new Date(this.date)
      // Extrayez l'année, le mois et le jour
      const year = dateObj.getFullYear()
      const month = dateObj.getMonth() + 1 // Les mois sont indexés à partir de 0, donc ajoutez 1 pour obtenir le mois correct
      const day = dateObj.getDate()
      // Ajoutez des zéros de tête si nécessaire
      const monthStr = month < 10 ? `0${month}` : month
      const dayStr = day < 10 ? `0${day}` : day
      // Concaténez l'année, le mois et le jour avec des tirets
      const formattedDate = `${year}-${monthStr}-${dayStr}`
      this.$http.post(`${API_URL}visite-distributeurs/`,
        {
          date: formattedDate,
          distributeur_id: this.distributeur.id,
          semaine: this.semaine,
          commercial_id: this.$route.params.id,
          produits: this.products,
        }).then(response => {
        console.log(response.data)
        showToast(this, 'info', 'Ajout', 'Element ajouté')
        this.$router.push({ name: 'distributeurs-details', params: { id: this.distributeur.id } })
      }).catch(error => {
        console.log(error)
      })
    },
    getWeekNumber(date) {
      return getWeek(new Date(date))
    },
    addProduct(product) {
      const newProduct = {
        id: product.id,
        quantity,
        quantity_entree: 0,
        new_status: true,
      }
      console.log(product)
      // newProduct.visite_quantite = 0
      // newProduct.date_peremption = ''
      // newProduct.new_status = true
      this.products.splice(this.products.indexOf(product) + 1, 0, newProduct)
    },
  },
}
</script>

<style scoped>

</style>
